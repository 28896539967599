<template>
  <div>
    <div class="data-header">
      <span class="data-min-title">{{cnName}}</span>
    </div>
    <div class="data-content">
      <ul class="title">
        <li>
          标段总数：
          <span>
            {{myData.bidCount}}
            <small>个</small>
          </span>
        </li>
        <li>
          网格总数：
          <span>
            {{myData.gridCount}}
            <small>个</small>
          </span>
        </li>
        <li>
          管理总面积：
          <span>
            {{myData.totalContractArea}}
            <small>㎡</small>
          </span>
        </li>
        <li>
          人员总数：
          <span>
            {{myData.personCount}}
            <small>个</small>
          </span>
        </li>
        <li>
          车辆总数：
          <span>
            {{myData.carCount}}
            <small>辆</small>
          </span>
        </li>
      </ul>
      <ul class="water">
        <li>
          <water :percent="myData.personnelQualificationRate"></water>
          <strong>今日人员合格率</strong>
          <small>较昨日{{(myData.personnelQualificationRateCompared>0?"+":"") + myData.personnelQualificationRateCompared+"%"}}</small>
        </li>
        <li>
          <water :percent="myData.sweeperRate"></water>
          <strong>今日机扫完成率</strong>
          <small>较昨日{{(myData.sweeperRateCompared>0?"+":"") + myData.sweeperRateCompared+"%"}}</small>
        </li>
        <li>
          <water :percent="myData.sprinkleRate"></water>
          <strong>今日机洗完成率</strong>
          <small>较昨日{{(myData.sprinkleRateCompared>0?"+":"") + myData.sprinkleRateCompared+"%"}}</small>
        </li>
        <li>
          <water :percent="myData.sprinkleRate"></water>
          <strong>今日垃圾清运完成率</strong>
          <small>较昨日{{(myData.sprinkleRateCompared>0?"+":"") + myData.sprinkleRateCompared+"%"}}</small>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import mixin from "./mixin-all";
import water from "./aylWater";
export default {
  mixins: [mixin],

  components: { water },

  data() {
    return {
      //↓/////钩子字段//////////////
      name: "sjgk",
      cnName: "数据概况",
      api: "/sanitation/dataCloudService/getDataOverview",
      isMock: false,
      comData: [],
      //↑////钩子字段//////////////
      debug:0,
    };
  },

  computed: {
    myData() {
      return this.comData;
    }
  },

  mounted() {
    let self = this;
    if(self.isMock){
      self.comData = getMockData();
      let timer = setInterval(async () => {
        self.comData = getMockData();
      }, 6000);
      
      self.$once("hook:beforeDestory", () => {
        clearInterval(timer);
        timer = null;
      });
    }
  }
};

function getMockData() {
  return {
    bidCount: 16, //标段总数
    gridCount: 124, //网格总数
    totalContractArea: 665867, //管理总面积
    personCount: 631, //人员总数
    carCount: 56, //车辆总数
    personnelQualificationRate: Math.random(100), //今日人员合格率
    personnelQualificationRateCompared: 3.6,
    sweeperRate: Math.random(100), //今日机扫完成率
    sweeperRateCompared: -0.6,
    sprinkleRate: Math.random(100), //今日机洗完成率
    sprinkleRateCompared: 1.2
  };
}
</script>
<style scoped>
.data-content {
  margin-top: -20px;
}
.title > li {
  float: left;
  width: 31%;
  background: rgba(3, 94, 255, 0.5);
  border: 1px solid #07e4fa;
  font-size: 0.6vw;
  padding: 0.5vh 0.6vw;
  margin: 0.5vh 0.26vw;
}
.title > li > span {
  color: #ffffff;
  font-size: 0.8vw;
  font-weight: bold;
}
.title > li > span > small {
  font-weight: normal;
  margin-left: 2px;
}
.water {
  clear: both;
}
.water > li {
  float: left;
  width: 24.8%;
  text-align: center;
  margin: auto;
}
.water > li > strong {
  color: #fff;
}
.water > li > small {
  display: block;
}
</style>