<template>
  <div class="data-main">
    <!--标题-->
    <div class="data-header">
      <div class="header-date-yymmdd">{{topDate}}</div>
      <div class="header-date-title">{{customInfo.dataVisual}}</div>
      <div class="header-date-text">欢迎进入{{customInfo.dataVisual}}</div>
    </div>
    <!--内容-->
    <div class="data-content">
      <el-row>
        <el-col :span="7">
          <div class="data-content-left">
            <div class="left-top">
              <ssrysj></ssrysj>
            </div>
            <div class="left-bottom">
              <ryzybj></ryzybj>
            </div>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="data-content-center">
            <div class="center-top">
              <jrcqsj></jrcqsj>
            </div>
            <div class="center-bottom">
              <sjgk></sjgk>
            </div>
          </div>
        </el-col>
        <el-col :span="7">
          <div class="data-content-right">
            <div class="right-top">
              <ssclsj></ssclsj>
            </div>
            <div class="right-bottom">
              <clbjtj></clbjtj>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--回到首页-->
    <div class="data-return" @click="$router.push('/home')">
      <img src="../../assets/images/cloud-hw/fanhui1.png" alt="返回" />
    </div>
  </div>
</template>
<script>
/**
 * 后续如果需要即时更新数据，则需要接受推送的消息，届时最好用RxJS重构 by 马波
 */

import ssrysj from "./components/ssrysj";
import ryzybj from "./components/ryzybj";
import jrcqsj from "./components/jrcqsj";
import sjgk from "./components/sjgk";
import ssclsj from "./components/ssclsj";
import clbjtj from "./components/clbjtj";

export default {
  components: { clbjtj, ssrysj, ryzybj, jrcqsj, sjgk, ssclsj },
  data() {
    return {
      // 标题和时间
      customInfo: JSON.parse(JSON.parse(sessionStorage.getItem('customInfo'))),
      topTitle: "智慧环卫大数据云图",
      topDate: null,
      topTime: null,
      topText: "欢迎进入智慧环卫系统大数据云图！",      
    };
  },
  methods: {
  },
  created() {
    this.topDate = this.$dayjs().format("YYYY-MM-DD HH:mm:ss");
    this.topTime = setInterval(() => {
      this.topDate = this.$dayjs().format("YYYY-MM-DD HH:mm:ss");
    }, 1000);
  }
};
</script>
<style lang='sass'>
    .data-main
        width: 100%
        height: 100vh
        color: #00fcff
        font-family: Source Han Sans CN
        font-size: 16px
        //background: radial-gradient(circle, #05094B, #050715,) !important
        background: url('../../assets/images/cloud-hw/bg-shang.png') no-repeat top / cover
        background-attachment: fixed
        background-repeat: no-repeat
        background-size: cover
        -moz-background-size: cover
        -webkit-background-size: cover
        padding: 0 !important
        //position: relative
        .data-return
            position: absolute
            top: 50%
            left: -2px
            transform: translateY(-50%)
            width: 42px
            height: 112px
            cursor: pointer
            z-index: 3

            img
                width: 100%
                height: 100%
        .data-header
            width: 100%
            height: 60px
            //background: url('../../assets/images/data-top.png') no-repeat center / cover
            position: relative
            .header-date-title
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)
                font-size: 2.25em
                letter-spacing: 2px
                font-weight: bold
                background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)
                text-shadow: 0 0 20px #D2D7E9
                -webkit-background-clip: text
                -webkit-text-fill-color: transparent
            .text-style
                font-size: 1.5em
                font-weight: 500
                font-family: PingFang SC
                color: rgba(3,195,251,1)
                letter-spacing: 2px

            .header-date-yymmdd
                @extend .text-style
                position: absolute
                top: 45px
                left: 2%

            .header-date-text
                @extend .text-style
                position: absolute
                top: 45px
                right: 2%
        .data-content
            width: 100%
            height: calc(100% - 80px)
            padding: 17px 55px 17px 47px
            &-left
                width: 100%
                height: 100%
                .left-top
                    width: 100%
                    height: calc(56vh - 12px)
                    background: url('../../assets/images/cloud-hw/kuang1.png') no-repeat
                    background-size: 100% 100%
                    margin-bottom: 10px
                    position: relative
                    .data-min-title
                        color: rgba(0,252,255,1)
                        font-weight: 500
                        font-size: 1.2em
                        position: absolute
                        left: 50%
                        top: 15px
                        transform: translateX(-50%)
                    .left-top-main
                        padding: 0 20px 0
                        font-weight: bold
                        color: rgba(255,255,255,1)
                        font-size: .875em
                        text-align: center
                        .main-item
                            .main-item-bg
                                width: calc( 100% - 10px )
                                margin: 20px 5px
                                height: 84px
                                background: url('../../assets/images/cloud-hw/yuanzhu1.png') no-repeat
                                background-size: 100% 100%
                                position: relative
                                .main-item-bg-test
                                    font-size: 1.875em
                                    font-weight: 400
                                    color: rgba(0,251,255,1)
                                    position: absolute
                                    top: 46%
                                    left: 50%
                                    transform: translate(-46%, -50%)
                        .left-top-tabel
                            height: calc(56vh - 230px)
                            //overflow-y: auto

                        /deep/ .el-table 
                            td
                                border-bottom: 0px solid #EBEEF5

                                &:hover
                                    background-color: transparent

                            tr
                                background-color: transparent

                                &:hover > td
                                    background-color: #212e3e !important

                            th.gutter
                                background: #07317E

                            &::before
                                height: 0px
                        

                            
                .left-bottom
                    width: 100%
                    height: calc(100vh - 56vh - 106px)
                    background: url('../../assets/images/cloud-hw/kuang2.png') no-repeat
                    background-size: 100% 100%
                    margin-top: 10px
                    position: relative
                    .data-min-title
                        color: rgba(0,252,255,1)
                        font-weight: 500
                        font-size: 1.2em
                        position: absolute
                        left: 50%
                        top: 15px
                        transform: translateX(-50%)
            
            &-center
                width: 100%
                height: 100%
                padding: 0 23px
                .center-top
                    width: 100%
                    height: calc(56vh - 20px)
                    margin-top: 8px
                .center-bottom
                    width: 100%
                    height: calc(100vh - 56vh - 106px)
                    background: url('../../assets/images/cloud-hw/kuang3.png') no-repeat
                    background-size: 100% 100%
                    margin-top: 10px
                    position: relative
                    .data-min-title
                        color: rgba(0,252,255,1)
                        font-weight: 500
                        font-size: 1.2em
                        position: absolute
                        left: 50%
                        top: 15px
                        transform: translateX(-50%)
            
            &-right
                width: 100%
                height: 100%
                .right-top
                    width: 100%
                    height: calc(45vh - 85px)
                    background: url('../../assets/images/cloud-hw/kuang4.png') no-repeat
                    background-size: 100% 100%
                    margin-bottom: 10px
                    position: relative
                    .data-min-title
                        color: rgba(0,252,255,1)
                        font-weight: 500
                        font-size: 1.2em
                        position: absolute
                        left: 50%
                        top: 15px
                        transform: translateX(-50%)
                .right-bottom
                    width: 100%
                    height: calc(100vh - 45vh - 33px)
                    background: url('../../assets/images/cloud-hw/kuang5.png') no-repeat
                    background-size: 100% 100%
                    margin-bottom: 10px
                    position: relative
                    .data-min-title
                        color: rgba(0,252,255,1)
                        font-weight: 500
                        font-size: 1.2em
                        position: absolute
                        left: 50%
                        top: 15px
                        transform: translateX(-50%)
</style>